@layer tailwind-base, primeng, tailwind-components, tailwind-utilities;

@import 'assets/styles/theme.css';
@import 'primeng/resources/primeng.css';

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
}

@layer tailwind-utilities {
  @tailwind utilities;
}

@layer tailwind-base {
  table {
    border-collapse: separate;
  }
}

@layer tailwind-utilities {
  * {
    scrollbar-width: thin;
    scrollbar-color: auto;
  }
}
